<script>
  import CounterBtn from "../../components/buttons/CounterBtn.vue";
  import CounterModal from "./CounterModal.vue";
  import CounterSlide from "../counter/CounterSlide.vue";
  import { mapGetters } from "vuex/dist/vuex.common.js";
  import { calculateTimeLeft } from "../../js/utils.js";

  export default {
    components: {
      CounterBtn,
      CounterModal,
      CounterSlide,
    },
    props: {
      button: {
        type: Object,
        required: true,
      },
      setCounterSlideDimensions: {
        type: Number,
        default: 18,
      },
      currentTime: {
        type: Date,
        required: true,
      }
    },
    data() {
      return {
        isVisibleModal: false,
        isFirstVisitToday: false,
      };
    },
    computed: {
      ...mapGetters(["screen"]),
      timeLeft() {
        const { days, hours, minutes, seconds } = calculateTimeLeft(this.currentTime, this.button.targetDate);
        const timeUnits = [
          { value: days, label: "Días" },
          { value: hours, label: "Horas" },
          { value: minutes, label: "Minutos" },
          { value: seconds, label: "Segundos" },
        ];
        const unit = timeUnits.find((unit) => unit.value > 0)

        return unit || { value: 0, label: "Finalizado" };
      },
    },
    watch: {
      'timeLeft.value'(newVal){
        if (newVal || !this.$refs.counterModal) {
          return;
        };

        this.$refs.counterModal.setShowAnimation()
        this.$refs.counterButtons.setShowAnimation()
      }
    },
    methods: {
      toggleModal(button, isVisible = false) {
        if (!button) {
          return;
        }

        this.isVisibleModal = isVisible;

        if(isVisible) {
          this.$refs.counterModal.setShowAnimation();
        }
      },
      activateLoaderForAllButtons() {
        const references = this.$refs.counterButtons;
        
        if (!references) {
          return;
        }
        
        if (this.button?.initialAnimation){
          references.startLoader(3000);
        } else {
          references.setImageChange()
        }        
      },
      verifyIsFirstVisitToday(){
        const currentDate = this.currentTime.toISOString().split("T")[0];
        const buttonViews = JSON.parse(localStorage.getItem("buttonViews")) || {}
        const buttonDateView = buttonViews[this.button._id];

        this.isFirstVisitToday = currentDate !== buttonDateView;

        buttonViews[this.button._id] = currentDate;

        localStorage.setItem("buttonViews", JSON.stringify(buttonViews))        
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.activateLoaderForAllButtons();
      });
    },
    beforeMount() {
      this.verifyIsFirstVisitToday();  
    },
  };
</script>


<template>
  <div class="buttonsWrapper">
      <CounterBtn
        ref="counterButtons"
        @hovered="() => toggleModal(button, true)"
        @unHovered="() => toggleModal(button, false)"
        @clicked="() => toggleModal(button, !isVisibleModal)"
        :button="button"    
      >
        <template v-slot:counter-slide="">
          <CounterSlide
            :shouldAnimate="isFirstVisitToday"
            :heightContainer="setCounterSlideDimensions"
            :targetDate="button.targetDate"
            :timeLeft="timeLeft"
          />
        </template>
      </CounterBtn>
      <transition name="slide">
        <CounterModal  ref="counterModal" v-show="isVisibleModal" :modalData="button.modalData" :timeLeft="timeLeft" :selectedAnimation="button.animation" class="buttonsWrapper__counterModal">
          <CounterSlide
            :shouldAnimate="isFirstVisitToday"
            :heightContainer="18"
            :targetDate="button.targetDate"
            :showDaysText="false"
            :timeLeft="timeLeft"
            :isModal="true"
          />
        </CounterModal>
      </transition>
    </div>
</template>

<style lang="scss">
  .buttonsWrapper {
    position: relative;
    &__counterModal {
      position: absolute;
      bottom: 0;
      right: 110%;
      pointer-events: none;
    }
    .slide-enter-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
    }
    .slide-leave-active {
      transition: transform 0.5s ease, opacity 0.5s ease;
    }
    .slide-enter {
      transform: translateX(100%);
      opacity: 0;
    }
    .slide-leave-to {
      transform: translateX(100%);
      opacity: 0;
    }
  }
</style>
