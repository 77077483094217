<script>
  import ButtonsWrapper from "./ButtonsWrapper.vue";
  import dataBtns from "../../data/dataButtons.json";
  import { mapGetters } from "vuex/dist/vuex.common.js";

  export default {
    components: {
      ButtonsWrapper
    },
    data() {
      return {
        currentTime: new Date(),
        buttons: dataBtns,
      };
    },
    computed: {
      ...mapGetters(["screen"]),
      screenWidth() {
        return this.screen.width;
      },
      setCounterSlideDimensions() {
        const dimensions = [
          { minWidth: 768, value: 22 },
          { minWidth: 540, value: 20 },
          { minWidth: 0, value: 18 },
        ];

        return dimensions.find((d) => this.screenWidth >= d.minWidth).value;
      },
      setButtonVisibility() {
        return this.buttons.filter((button) => {
          const startDate = new Date(button.initialDate);
          const expDate = new Date(button.endDate);

          return startDate <= this.currentTime && expDate > this.currentTime;
        });
      },
    },
    methods: {
      updateTimer() {
        this.timer = setInterval(() => {
          this.currentTime = new Date();          
        }, 1000);
      },
    },
    mounted() {
      this.updateTimer();
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
  };
</script>

<template>
  <div class="buttonsContainer">
    <ButtonsWrapper v-for="button in setButtonVisibility" :button="button" :setCounterSlideDimensions="setCounterSlideDimensions" :currentTime="currentTime" :key="button._id" />
  </div>
</template>

<style lang="scss">
  .buttonsContainer {
    @include Flex(column);
    gap: 15px;
    position: fixed;
    bottom: 170px;
    right: 10px;
    z-index: 11;
    @include pocketWidth() {
      right: 6px;
    }
    @include tabletWidth() {
      right: 10px;
      bottom: 105px;
    }
  }
</style>
