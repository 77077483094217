<script>
  export default {
    name: "CounterSlide",
    props: {
      targetDate: {
        type: String,
        required: true,
      },
      isModal: {
        type: Boolean,
        default: false,
      },
      shouldAnimate: {
        type: Boolean,
      },
      showDaysText: {
        type: Boolean,
        default: true,
      },
      heightContainer: {
        type: Number,
        default: 18,
      },
      timeLeft: {
        type: Object,
        default: {
          value: 0,
          label: "Finalizado",
        },
      },
    },
    data() {
      return {
        timeOutId: null,
        delayedData: 0,
        delayedDataTimeoutId: null,
      };
    },
    computed: {
      totalHeight() {
        return this.timeLeft.value * this.heightContainer;
      },
      finalTranslationY() {
        return this.totalHeight - this.heightContainer;
      },
    },
    methods: {
      initiateAnimation() {
        if(!this.shouldAnimate || !this.$refs.numbersWrapper){
          return;
        }

        this.$refs.numbersWrapper.style.transform = `translateY(-${0}px)`;
        this.$refs.numbersWrapper.style.transition = "transform 3s cubic-bezier(0.1, 0.7, 0.1, 1)";

        clearTimeout(this.timeOutId);
        this.timeOutId = setTimeout(() => {
          this.$refs.numbersWrapper.style.transform = `translateY(-${this.finalTranslationY}px)`;
        }, 100);
      },
      moveNumber() {
        if(!this.$refs.numbersWrapper){
          return;
        }
        
        this.$refs.numbersWrapper.style.transition = "transform 0.5s ease-in-out";
        this.$refs.numbersWrapper.style.transform = `translateY(-${this.finalTranslationY}px)`;
      },
    },
    watch: {
      'timeLeft.value': {
        handler(newValue) {
          this.$nextTick(() => {
            this.moveNumber();   
            clearTimeout(this.delayedDataTimeoutId);
            this.delayedDataTimeoutId = setTimeout(() => {
              this.delayedData = newValue;            
            }, 500);
          });
        },
        immediate: true,
      }, 
    },
    mounted() {      
      this.$nextTick(() => {
        this.initiateAnimation();   
      });
    },
    beforeDestroy() {
      clearTimeout(this.timeOutId);
      clearTimeout(this.delayedDataTimeoutId);
    },
  };
</script>

<template>
  <div :class="['counterSlide', isModal &&  'counterSlide--modal']" >
    <div
      class="counterSlide__container"
      :style="{
        height: heightContainer + 'px',
        fontSize: heightContainer + 'px',
      }"
    >
      <div class="counterSlide__numbers" ref="numbersWrapper">
        <div class="counterSlide__number" v-for="number in delayedData" :key="number" >
          {{ number }}
        </div>
      </div>
    </div>
    <p :class="['counterSlide__dayText', !delayedData && 'counterSlide__dayText--onlyTxt' ]" v-if="showDaysText">{{ timeLeft.label }}</p>
  </div>
</template>

<style scoped lang="scss">
  .counterSlide {
    position: relative;
    font-family: $newFont_medium;
    font-weight: bold;
    letter-spacing: 1.2px;
    text-shadow: 0px 0px 1px #000000b6;
    &--modal {
      text-shadow: none;
    }
    &__container {
      overflow: hidden;
      line-height: 100%;
    }
    &__dayText {
      width: 100%;
      font-size: 10px;
      line-height: 1.2;
      font-family: $newFont;
      font-style: italic;
      &--onlyTxt{
        position: absolute;
        bottom: 0;
      }
    }
  }
</style>
